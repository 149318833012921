import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

var Masonry = require('masonry-layout');

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  scrollSpy('.header-nav-sub:visible', { offset: 0 })

  if (window.location.pathname == '/') {
    smoothScroll('a[href*="/#"]', { offset: 130 })
  }

  var msnry = new Masonry('.grid-mansonry', {
    itemSelector: '.grid-content-col',
    columnWidth: '.grid-sizer',
    percentPosition: true
  })
})
